import React from 'react'

import PropTypes from 'prop-types'

import './store-banner.css'

const StoreBanner = (props) => {
  return (
    <div className={`store-banner-store-banner ${props.rootClassName} `}>
      <a
        href="https://apps.apple.com/us/app/apple-wallet/id1160481993"
        target="_blank"
        rel="noreferrer noopener"
        className="store-banner-link"
      >
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="store-banner-image"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.walletnfcrel&hl=en&gl=US"
        target="_blank"
        rel="noreferrer noopener"
        className="store-banner-link1"
      >
        <img
          alt={props.image_alt1}
          src={props.image_src1}
          className="store-banner-image1"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=io.walletpasses.android&hl=en&gl=US"
        target="_blank"
        rel="noreferrer noopener"
        className="store-banner-link1"
      >
        <img
          alt={props.image_alt2}
          src={props.image_src2}
          className="store-banner-image1"
        />
      </a>
    </div>
  )
}

StoreBanner.defaultProps = {
  rootClassName: '',
  image_alt: 'Apple Wallet',
  image_src: '/playground_assets/apple-wallet.png',
  image_alt1: 'Google Wallet',
  image_src1: '/playground_assets/google-wallet.png',
  image_alt2: 'Wallet Passes',
  image_src2: '/playground_assets/wallet-passes.png',
}

StoreBanner.propTypes = {
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt: PropTypes.string,
}

export default StoreBanner
