import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderContainer from '../components/header-container'
import HowToCreate from '../components/how-to-create'
import HowToCreateDark from '../components/how-to-create-dark'
import HowToCreateNoImg1 from '../components/how-to-create-no-img1'
import HowToCreateNoImg from '../components/how-to-create-no-img'
import Footer from '../components/footer'
import './features.css'

const Features = (props) => {
  return (
    <div className="features-container">
      <Helmet>
        <title>Features - CloudCards</title>
        <meta property="og:title" content="Features - CloudCards" />
      </Helmet>
      <HeaderContainer rootClassName="header-container-root-class-name1"></HeaderContainer>
      <h1 className="features-text">
        <span>Product Features</span>
        <br></br>
      </h1>
      <div className="features-card-types">
        <div className="features-container01">
          <div className="features-container02">
            <h1 className="features-text03">Create Digital Cards</h1>
            <span className="features-text04">
              {' '}
              Creating cards for your clients is simple like magic
            </span>
          </div>
          <div className="features-container03">
            <div className="features-container04">
              <HowToCreate
                card_name="Web form"
                rootClassName="how-to-create-root-class-name7"
                card_image_src="/playground_assets/CloudCards%20Web%20Form.gif"
                card_description="Design your own form for issuing cards. Add fields to get first name, last name, phone number and date of birth."
                card_classes="how-to-create-image mockup_width"
              ></HowToCreate>
              <HowToCreate
                card_name="Duplicate control"
                rootClassName="how-to-create-root-class-name12"
                card_image_src="/playground_assets/CloudCards%20Duplicate%20Control.gif"
                card_description="When clients fill out the issue form, an algorithm checks the existing customer base for duplicate information."
                card_classes="how-to-create-image mockup_width"
              ></HowToCreate>
              <HowToCreate
                card_name="CRM"
                rootClassName="how-to-create-root-class-name13"
                card_image_src="/playground_assets/CloudCards%20CRM.gif"
                card_description="After filling out the card issue form, customer data immediately goes to CRM."
                card_classes="how-to-create-image mockup_width"
              ></HowToCreate>
              <HowToCreate
                card_name="GDPR ready"
                rootClassName="how-to-create-root-class-name14"
                card_image_src="/playground_assets/gdpr-logo_1.webp"
                card_description="General Data Protection Regulation (GDPR)"
              ></HowToCreate>
              <HowToCreate
                card_name="CCPA ready"
                rootClassName="how-to-create-root-class-name15"
                card_image_src="/playground_assets/ccpa-logo_1.webp"
                card_description="California Consumer Privacy Act (CCPA)"
              ></HowToCreate>
            </div>
          </div>
        </div>
      </div>
      <div className="features-change-container">
        <div className="features-change">
          <div className="features-container05">
            <div className="features-container06">
              <h1 className="features-text05">Fully customizable cards</h1>
              <span className="features-text06"> Change cards on the fly</span>
            </div>
            <div className="features-container07">
              <div className="features-container08">
                <HowToCreateDark
                  card_name="Design"
                  rootClassName="how-to-create-dark-root-class-name2"
                  card_image_src="/playground_assets/CloudCards%20Card%20Design.gif"
                  card_description="The color, logo, conditions of the promotion and any information on the card can be changed without the need to reissue the cards."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Description"
                  rootClassName="how-to-create-dark-root-class-name"
                  card_image_src="/playground_assets/CloudCards%20Card%20Description.gif"
                  card_description="Any changes in information are immediately displayed on the card, which allows you to keep your clients informed about the latest news and show them the updated data."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Links"
                  rootClassName="how-to-create-dark-root-class-name1"
                  card_image_src="/playground_assets/CloudCards%20Links.gif"
                  card_description="Add and edit social links, websites, online booking and others."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-push">
        <div className="features-container09">
          <div className="features-container10">
            <h1 className="features-text07">Free PUSH instead SMS</h1>
            <span className="features-text08">
              {' '}
              Totally free &amp; unlimited
            </span>
          </div>
          <div className="features-container11">
            <div className="features-container12">
              <HowToCreate
                card_name="Transaction"
                rootClassName="how-to-create-root-class-name6"
                card_image_src="/playground_assets/svg_push_1.svg"
                card_description="Automatic PUSH notifications with information about bonus balance changes. Sent instantly after each transaction."
              ></HowToCreate>
              <HowToCreate
                card_name="Marketing"
                rootClassName="how-to-create-root-class-name8"
                card_image_src="/playground_assets/svg_push_2.svg"
                card_description="Notifications are sent manually as an SMS mailing. Send PUSH to the entire customer base or a selected segment."
              ></HowToCreate>
              <HowToCreate
                card_name="Geolocation"
                rootClassName="how-to-create-root-class-name9"
                card_image_src="/playground_assets/svg_push_3.svg"
                card_description="Automatic PUSH notifications within 330 feet of your business locations. Displayed on loyalty cardholders' lock screen."
              ></HowToCreate>
              <HowToCreate
                card_name="Quality control"
                rootClassName="how-to-create-root-class-name10"
                card_image_src="/playground_assets/svg_push_4.svg"
                card_description="Automatic PUSHs are sent 1 hour after each transaction with the offer to leave feedback on the service quality."
              ></HowToCreate>
              <HowToCreate
                card_name="Reminder"
                rootClassName="how-to-create-root-class-name11"
                card_image_src="/playground_assets/svg_push_5.svg"
                card_description="Automatic PUSHs are sent 28 days after the last purchase with an invitation to make a repeat purchase."
              ></HowToCreate>
              <HowToCreate
                card_name="Create your way"
                rootClassName="how-to-create-root-class-name16"
                card_image_src="/playground_assets/svg_push_6.svg"
                card_description="The PUSH automation builder allows you to configure any script for sending PUSH notifications."
              ></HowToCreate>
            </div>
          </div>
        </div>
      </div>
      <div className="features-interactive-container">
        <div className="features-change1">
          <div className="features-container13">
            <div className="features-container14">
              <h1 className="features-text09">Interactive</h1>
              <span className="features-text10">
                {' '}
                Maintain perfect communication with clients
              </span>
            </div>
            <div className="features-container15">
              <div className="features-container16">
                <HowToCreateDark
                  card_name="Quality control"
                  rootClassName="how-to-create-dark-root-class-name3"
                  card_image_src="/playground_assets/CloudCards%20Quality%20Control.gif"
                  card_description="Quality control link is available on all cards in the description."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Referral program"
                  rootClassName="how-to-create-dark-root-class-name4"
                  card_image_src="/playground_assets/CloudCards%20Referral.gif"
                  card_description="The client can share the card with friends and earn points. Expand your customer base at no cost."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Phone"
                  rootClassName="how-to-create-dark-root-class-name5"
                  card_image_src="/playground_assets/CloudCards%20Phone.gif"
                  card_description="Interactive link to the phone number. The client can always contact you in 1 click."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Map links"
                  rootClassName="how-to-create-dark-root-class-name8"
                  card_image_src="/playground_assets/CloudCards%20Map.gif"
                  card_description="By clicking on the address, the map application on the device will open and lead a customer to your business."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Social links"
                  rootClassName="how-to-create-dark-root-class-name7"
                  card_image_src="/playground_assets/CloudCards%20Social.gif"
                  card_description="Post links to social networks. Edit anytime."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
                <HowToCreateDark
                  card_name="Webpage links"
                  rootClassName="how-to-create-dark-root-class-name6"
                  card_image_src="/playground_assets/CloudCards%20Links.gif"
                  card_description="Post links to any website. Edit anytime."
                  card_classes="how-to-create-image mockup_width"
                ></HowToCreateDark>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-interactive-container new-container">
        <div className="features-container17">
          <div className="features-card-builder">
            <div className="features-container18">
              <div className="features-container19">
                <h1 className="features-heading">Card Builder</h1>
                <div className="features-container20">
                  <HowToCreateNoImg1
                    card_name="111 card templates"
                    rootClassName="how-to-create-no-img1-root-class-name"
                    card_description="Just choose the one that suits you."
                  ></HowToCreateNoImg1>
                  <HowToCreateNoImg1
                    card_name="Your own design"
                    rootClassName="how-to-create-no-img1-root-class-name3"
                    card_description="Add your logo and icon, customize colors. Create your card in your brand style."
                  ></HowToCreateNoImg1>
                  <HowToCreateNoImg1
                    card_name="Promotion rules"
                    rootClassName="how-to-create-no-img1-root-class-name2"
                    card_description="Add conditions of the promotion. Show the client how easy it is to get bonuses."
                  ></HowToCreateNoImg1>
                  <HowToCreateNoImg1
                    card_name="Issue form"
                    rootClassName="how-to-create-no-img1-root-class-name1"
                    card_description="Add fields to the questionnaire form and get the data that you need."
                  ></HowToCreateNoImg1>
                </div>
              </div>
            </div>
            <div id='laptop_img'>
              <img className='laptop_image' src="/playground_assets/CloudCards%20Laptop.gif" alt=""/>
            </div>
          </div>
        </div>
        <img style={{ position: 'relative' }} className='other_laptop_image' src="/playground_assets/CloudCards%20Laptop.gif" alt=""/>
      </div>
      <div className="features-advertise-container">
        <div className="features-change2">
          <div className="features-container21">
            <div className="features-container22">
              <h1 className="features-text11">Advertise your promotion</h1>
            </div>
            <div className="features-container23">
              <h3 className="features-text12">Online</h3>
              <div className="features-container24">
                <HowToCreateNoImg
                  card_name="Messenger sharing"
                  rootClassName="how-to-create-no-img-root-class-name"
                  card_description="Share the link to the map on social networks and messengers with your clients. It's free."
                ></HowToCreateNoImg>
                <HowToCreateNoImg
                  card_name="Website"
                  rootClassName="how-to-create-no-img-root-class-name2"
                  card_description="Issue cards via a widget on your website."
                ></HowToCreateNoImg>
                <HowToCreateNoImg
                  card_name="Referral sharing"
                  rootClassName="how-to-create-no-img-root-class-name1"
                  card_description="Your customers can share the card and receive bonuses from your company. Grow your customer base for free."
                ></HowToCreateNoImg>
              </div>
              <h3 className="features-text13">SMS</h3>
              <div className="features-container25">
                <HowToCreateNoImg
                  card_name="Import clients"
                  rootClassName="how-to-create-no-img-root-class-name3"
                  card_description="You can upload a client base and issue digital cards within minutes."
                ></HowToCreateNoImg>
                <HowToCreateNoImg
                  card_name="Send SMS"
                  rootClassName="how-to-create-no-img-root-class-name4"
                  card_description="Additional paid option."
                ></HowToCreateNoImg>
                <HowToCreateNoImg
                  card_name="Card status"
                  rootClassName="how-to-create-no-img-root-class-name5"
                  card_description="Control status of installed cards online through CRM."
                ></HowToCreateNoImg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Features
