import { Modal, Button } from "react-bootstrap";
import './modal.css'

const Example = props => {
    const { show, onClose } = props;

  return (
    <div>
    <Modal show={show} onHide={onClose} centered>
        <Modal.Body>
          <img class="install_image" src={props.card_image_src} alt={props.card_name}/>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <a href={props.link} className="btn installBtn" target="blank">
              Install Card
            </a>
        </Modal.Footer>
    </Modal>
    </div>
  );
}

export default Example;