import React from 'react'

import PropTypes from 'prop-types'

import './how-to-create.css'

const HowToCreate = (props) => {
  return (
    <div className={`how-to-create-container ${props.rootClassName} `}>
      <div className="how-to-create-container1">
        <img
          alt={props.card_image}
          src={props.card_image_src}
          className={props.card_classes}
        />
      </div>
      <div className="how-to-create-container2">
        <h1 className="how-to-create-text">{props.card_name}</h1>
        <span className="how-to-create-text1">{props.card_description}</span>
      </div>
    </div>
  )
}

HowToCreate.defaultProps = {
  card_description:
    'For example, every 10th coffee as a gift or 10% of the purchase amount is accumulated in the form of points for future purchases',
  card_image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  card_name: 'Create a loyalty card',
  card_image: 'image',
  rootClassName: '',
  card_classes: "how-to-create-image",
}

HowToCreate.propTypes = {
  card_description: PropTypes.string,
  card_image_src: PropTypes.string,
  card_name: PropTypes.string,
  card_image: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default HowToCreate
