import React, { useState } from 'react';

import { Helmet } from 'react-helmet'

import HeaderContainer from '../components/header-container'
import FeatureCard1 from '../components/feature-card1'
import Footer from '../components/footer'
import './roadmap.css'

const Roadmap = (props) => {

  return (
    <div className="roadmap-container">
      <Helmet>
        <title>Roadmap - CloudCards</title>
        <meta property="og:title" content="Roadmap - CloudCards" />
      </Helmet>
      <HeaderContainer rootClassName="header-container-root-class-name"></HeaderContainer>

      <h1 className="roadmap-text09">
        <span>Roadmap</span>
        <br></br>
      </h1>

      <div className="roadmap-container08">
        <div className="roadmap-container09">
          <div className="roadmap-container10">
            <h2 className="roadmap-text12">In Progress</h2>
            <FeatureCard1
              text="We know that users need to connect loyalty with thems e-shop"
              heading="Shopify integration module"
            ></FeatureCard1>
            <FeatureCard1
              text="We get a lot of requests to add a Facebook authorization feature. We will also add the ability to import some information from the Facebook business page to speed up the loyalty card creation process."
              heading="Login via Facebook"
            ></FeatureCard1>
            <FeatureCard1
              text="We get a lot of requests to add a Google authorization feature. We will also add the ability to import some information from the Google My business page to speed up the loyalty card creation process."
              heading="Login via Google"
            ></FeatureCard1>
          </div>
          <div className="roadmap-container11">
            <h2 className="roadmap-text13">Up Next</h2>
            <FeatureCard1
              text="A lot of customers have requested the addition of a new type of membership card. We will add it soon :)"
              heading="Membership card type"
            ></FeatureCard1>
            <FeatureCard1
              text="We know that our customers want to send not only push messages, but also Email to their customer base. We are working on the possibility of adding Email mailings to the client base directly in the CRM CloudCards."
              heading="Email marketing"
            ></FeatureCard1>
            <FeatureCard1
              text="We get a lot of requests for custom scenarios for bonus points and customer notifications. We are developing our own Automation Designer in which you can set up any events flow after any trigger is triggered."
              heading="Automation designer"
            ></FeatureCard1>
          </div>
          <div className="roadmap-container12">
            <h2 className="roadmap-heading">Future</h2>
            <FeatureCard1
              text="We know that users need to connect loyalty with thems e-shop"
              heading="WooCommerce integration module"
            ></FeatureCard1>
            <FeatureCard1
              text="We see a lot of requests from customers who would like to see bonus cards and their balance directly in the Facebook Messenger. Facebook Messenger will also allow us to reach Android users who do not use Google Pay.   Facebook Messenger bot will allow to install cards directly in messenger and receive data on the bonus balance in real time. "
              heading="Facebook Messenger chatbot for cards installment"
            ></FeatureCard1>
            <FeatureCard1
              text="We received several requests for integration with Pabbly service. We will do it in the near future."
              heading="Pabbly Integration"
            ></FeatureCard1>
            <FeatureCard1
              text="We have received many requests to add a birthday gift (stamp, points or award) for a client. We will add this option for each card type."
              heading="Birthday gifts"
            ></FeatureCard1>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Roadmap
