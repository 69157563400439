import { Helmet } from "react-helmet";
import "./home.css";
import "./privacy.css";

const Cookie = (props) => {
  return (
    <div
      className="home-container"
      style={{ fontFamily: "CircularStd,Arial,sans-serif" }}
    >
      <Helmet>
        <title>CloudCards Cookie Policy</title>
        <meta property="og:title" content="CloudCards Cookie Policy" />
      </Helmet>
      <div className="home-card-types w-100 px-3 documents" style={{ maxWidth: "820px", display: "block" }}>
          <div class="t-feed__post-popup__title-wrapper">
            <h1 class="js-feed-post-title t-feed__post-popup__title t-title t-title_xxs">
              Cookie Policy
            </h1>
          </div>
          <div
            id="feed-text"
            class="r t-feed__post-popup__text-wrapper"
            data-animationappear="off"
          >
            <div class="js-feed-post-text t-feed__post-popup__text t-text t-text_md">
              <section>
                This Cookie Policy (this &ldquo;Policy&rdquo;) describes how and
                when CloudCards. (&ldquo;CloudCards,&rdquo; &ldquo;we,&rdquo;
                &ldquo;us,&rdquo; or &ldquo;our&rdquo;) uses cookies on our
                website located at{" "}
                <a
                  href="https://www.cloudcards.app/"
                  rel="noopener noreferrer"
                >
                  <u>https://cloudcards.app</u>
                </a>{" "}
                (the &ldquo;Site&rdquo;), as well as how you can manage the
                cookie settings in your browser. By continuing to visit the Site
                or use our services, you are agreeing to use the cookies and
                similar technologies for the purposes we describe in this
                Policy.
                <br />
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">What is a Cookie?</h2>
                &nbsp;
                <br />A cookie is a small text file downloaded to your browser
                when you visit a website that enables certain features and
                functionality. You can think of cookies as providing a
                &lsquo;memory&rsquo; for a website so that it can recognize
                users and user activity over time. For instance, a cookie may be
                used to record where you have paused a video so that you can
                resume playing it from the same spot later on. Most web pages
                contain elements from multiple web domains, so when you visit
                the Site your browser may receive cookies from several sources.
                This may include third parties that CloudCards has hired to
                provide services such as site analytics or ad targeting.
                CloudCards also uses web beacons, which are electronic images
                that may be used in our services or emails and help deliver
                cookies, count visits and understand usage and campaign
                effectiveness.
                <br />
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">
                  How CloudCards Uses Cookies and Other Tracking Technologies
                </h2>
                &nbsp;
                <br />
                CloudCards uses cookies and similar technologies, such as web
                beacons, to help us understand the profile of our visitors,
                recognize when you are signed in to your account, and provide
                you with a better user experience. We may also use cookies to
                analyze and track data, determine the popularity of certain
                content, deliver advertising and content targeted to your
                interests on our services and other websites and better
                understand your online activity.
                <br />
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">Third-Party Use of Cookies</h2>
                &nbsp;
                <br />
                Some content or applications on the Site are served by third
                parties, including advertisers, ad networks and servers, content
                providers, and application providers. These third parties may
                use cookies alone or in conjunction with web beacons or other
                tracking technologies to collect information about you when you
                use our website. The information they collect may be associated
                with your personal information or they may collect information,
                including personal information, about your online activities
                over time and across different websites and other online
                services. They may use this information to provide you with
                interest-based (behavioral) advertising or other targeted
                content.
                <br />
                &nbsp;
                <br />
                We do not control these third parties tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly.
                <br />
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">
                  What are your Choices Regarding Cookies?
                </h2>
                &nbsp;
                <br />
                Most web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove or
                reject browser cookies. Please note that if you choose to remove
                or reject cookies, this could affect the availability and
                functionality of our services. To learn more about how to
                control cookie settings through your browser:
                <br />
                &nbsp;
                <br />
                <ul>
                  <li>
                    Please go&nbsp;to&nbsp;
                    <a
                      href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US&amp;roistat_visit=168531"
                      rel="noopener noreferrer"
                    >
                      <u>
                        https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US
                      </u>
                    </a>
                    &nbsp;to learn more about the &ldquo;Private Browsing&rdquo;
                    setting and managing cookie settings in Firefox;
                  </li>
                  <li>
                    Please go to{" "}
                    <a
                      href="https://support.google.com/chrome/answer/95647?hl=en&amp;roistat_visit=168531"
                      rel="noopener noreferrer"
                    >
                      <u>
                        https://support.google.com/chrome/answer/95647?hl=en
                      </u>
                    </a>
                    &nbsp;to learn more about &ldquo;Incognito&rdquo; and
                    managing cookie settings in Chrome;
                  </li>
                  <li>
                    Please go to&nbsp;
                    <a
                      href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d?roistat_visit=168531"
                      rel="noopener noreferrer"
                    >
                      <u>
                        https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
                      </u>
                    </a>
                    &nbsp;to learn more about &ldquo;InPrivate&rdquo; and
                    managing cookie settings in Internet Explorer; or
                  </li>
                  <li>
                    Please go to&nbsp;
                    <a
                      href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac?roistat_visit=168531"
                      rel="noopener noreferrer"
                    >
                      <u>
                        https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                      </u>
                    </a>
                    &nbsp;to learn more about &ldquo;Private Browsing&rdquo; and
                    managing cookie settings in Safari.
                  </li>
                </ul>
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">
                  Where can you find more information about cookies?
                </h2>
                &nbsp;
                <br />
                If you want to learn more about cookies, or how to control,
                disable or delete them, please visit&nbsp;
                <a
                  href="http://www.aboutcookies.org/?roistat_visit=168531"
                  rel="noopener noreferrer"
                >
                  <u>http://www.aboutcookies.org</u>
                </a>{" "}
                for detailed guidance. In addition, certain third-party
                advertising networks, including Google, permit users to opt-out
                of or customize preferences associated with their internet
                browsing. To learn more about this feature from Google, please
                go to{" "}
                <a
                  href="https://adssettings.google.com/u/0/authenticated?hl=en&amp;roistat_visit=168531"
                  rel="noopener noreferrer"
                >
                  <u>https://adssettings.google.com/u/0/authenticated?hl=en</u>
                </a>
                .<br />
                &nbsp;
                <br />
                You can learn about opting out of third-party targeting cookies
                on the Digital Advertising Alliance&rsquo;s consumer choice
                page&nbsp;at&nbsp;
                <a
                  href="https://optout.aboutads.info/?c=2&amp;lang=EN&amp;roistat_visit=168531"
                  rel="noopener noreferrer"
                >
                  <u>https://optout.aboutads.info/?c=2&amp;lang=EN</u>
                </a>
                ,&nbsp;or the Network Advertising Initiative&rsquo;s consumer
                choice page&nbsp;at&nbsp;
                <a
                  href="https://optout.networkadvertising.org/?c=1&amp;roistat_visit=168531"
                  rel="noopener noreferrer"
                >
                  <u>https://optout.networkadvertising.org/?c=1</u>
                </a>
                , or the European Interactive Digital Advertising Alliance
                consumer choice page&nbsp;
                <a
                  href="https://www.youronlinechoices.com/?roistat_visit=168531"
                  rel="noopener noreferrer"
                >
                  <u>https://www.youronlinechoices.com/</u>
                </a>
                .<br />
                &nbsp;
                <br />
                <h2 class="t-redactor__h2">Changes to this Policy</h2>
                &nbsp;
                <br />
                We may change this Policy from time to time. If we make changes,
                we will notify you by revising the date at the top of the
                policy. By accessing the Site or using our services, you agree
                to the latest version of this Policy. We encourage you to review
                this Policy whenever you access the Site or otherwise interact
                with us to stay informed about our practices
              </section>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Cookie;
