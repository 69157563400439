import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import Home from './views/home'
import Features from './views/features'
import PricePage from './views/price-page'
import Roadmap from './views/roadmap'
import Contact from './views/contact'
import Privacy from './views/privacy'
import Cookie from './views/cookie'
import Terms from './views/terms'
import Subscription from './views/subscription'
import Acceptable from './views/acceptable';

const App = () => {
  return (
    <Router>
      <div>
        <Route exact component={Home} path="/" />
        <Route exact component={Features} path="/features" />
        <Route exact component={PricePage} path="/pricing" />
        <Route exact component={Roadmap} path="/roadmap" />
        <Route exact component={Contact} path="/contact-us" />
        <Route exact component={Privacy} path="/privacy-policy" />
        <Route exact component={Cookie} path="/cookie-policy" />
        <Route exact component={Terms} path="/terms-and-conditions" />
        <Route exact component={Subscription} path="/subscription-agreement" />
        <Route exact component={Acceptable} path="/acceptable-use-policy" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
