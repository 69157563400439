import React from 'react'

import PropTypes from 'prop-types'

import './how-to-create-no-img1.css'

const HowToCreateNoImg1 = (props) => {
  return (
    <div className={`how-to-create-no-img1-container ${props.rootClassName} `}>
      <div className="how-to-create-no-img1-container1">
        <h1 className="how-to-create-no-img1-text">{props.card_name}</h1>
        <span className="how-to-create-no-img1-text1">
          {props.card_description}
        </span>
      </div>
    </div>
  )
}

HowToCreateNoImg1.defaultProps = {
  card_description:
    'For example, every 10th coffee as a gift or 10% of the purchase amount is accumulated in the form of points for future purchases',
  card_name: 'Create a loyalty card',
  rootClassName: '',
}

HowToCreateNoImg1.propTypes = {
  card_description: PropTypes.string,
  card_name: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default HowToCreateNoImg1
