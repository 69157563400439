import React from 'react'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './app-links.css'

const AppLinks = (props) => {
  return (
    <div className={`app-links-store-banner ${props.rootClassName} `}>
      <div className="app-links-container">
        <div className="app-links-container1">
          <a
            href="https://play.google.com/store/apps/details?id=app.scannerapp"
            target="_blank"
            rel="noreferrer noopener"
            className="app-links-link"
          >
            <img
              alt={props.image_alt1}
              src={props.image_src1}
              className="app-links-image"
            />
          </a>
          <a
            href="https://apps.apple.com/ru/app/scanner-app-wl/id1616692414?l=en"
            target="_blank"
            rel="noreferrer noopener"
            className="app-links-link1"
          >
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="app-links-image1"
            />
          </a>
        </div>
        <div className="app-links-container2">
          <a
            href="https://my.cloudcards.app/scanner-app/login"
            target="_blank"
            rel="noreferrer noopener"
            className="app-links-link2 button d-flex justify-content-center align-items-center"
            style={{ height: "56px" }}
          >
            {props.scanner_link} &nbsp; <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </div>
      </div>
    </div>
  )
}

AppLinks.defaultProps = {
  rootClassName: '',
  image_alt1: 'image',
  scanner_link: 'Open Scanner',
  image_src1: '/playground_assets/g-play.png',
  image_src: '/playground_assets/a-store.png',
  image_alt: 'image',
}

AppLinks.propTypes = {
  rootClassName: PropTypes.string,
  image_alt1: PropTypes.string,
  scanner_link: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
}

export default AppLinks
