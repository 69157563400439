import { Helmet } from "react-helmet";
import "./home.css";
import "./privacy.css";

const Acceptable = (props) => {
  return (
    <div
      className="home-container"
      style={{ fontFamily: "CircularStd,Arial,sans-serif" }}
    >
      <Helmet>
        <title>CloudCards Acceptable Use Policy</title>
        <meta property="og:title" content="CloudCards Acceptable Use Policy" />
      </Helmet>
      <div
        className="home-card-types w-100 px-3 documents"
        style={{ maxWidth: "820px", display: "block" }}
      >
        <div class="t-feed__post-popup__title-wrapper">
          <h1 class="js-feed-post-title t-feed__post-popup__title t-title t-title_xxs">
            Acceptable Use Policy
          </h1>
        </div>
        <div
          id="feed-text"
          class="r t-feed__post-popup__text-wrapper"
          data-animationappear="off"
        >
          <div class="js-feed-post-text t-feed__post-popup__text t-text t-text_md">
            <section>
              This Acceptable Use Policy (this &ldquo;Policy&rdquo;) describes
              prohibited uses of the digital customer loyalty program services
              as well as other services (the &ldquo;Service&rdquo;) offered by
              CloudCards. (&ldquo;CloudCards&rdquo;) and the related website
              located at{" "}
              <a href="https://cloudcards.app/" rel="noopener noreferrer">
                <u>https://cloudcards.app</u>
              </a>
              &nbsp;(the &ldquo;Site&rdquo;). This policy supplements, and is
              incorporated into, Cloudcards&rsquo; Subscription Agreement
              (currently published at&nbsp;
              <a href="https://cloudcards.app/subscription-agreement">
                <u>https://cloudcards.app/subscription-agreement</u>
              </a>
              ) (the &ldquo;Subscription Agreement&rdquo;), and the Terms of Use
              for the Site (currently published at&nbsp;
              <a href="https://cloudcards.app/terms-and-conditions">
                <u>https://cloudcards.app/terms-and-conditions</u>
              </a>
              ) (the &ldquo;Terms of Use&rdquo;). If any provision in this
              Policy conflicts with a term in the Subscription Agreement or the
              Terms of Use, then the applicable provisions of the Subscription
              Agreement and the Terms of Use will prevail unless the term in
              this Policy specifically states that it will prevail.
              <br />
              <br />
              If you violate this Policy, CloudCards may suspend or terminate
              your use of the Service or access to the Site. CloudCards' right
              to suspend or terminate your use of the Service or Site applies
              even if a breach is committed unintentionally or without your
              authorization if CloudCards believes that suspension or
              termination is necessary to ensure compliance with laws or to
              protect the rights, safety, privacy, security or property of
              CloudCards, its customers or third parties.
              <br />
              <br />
              CloudCards may modify this Policy at any time by posting a revised
              version on the Site. By using the Service or accessing the Site,
              you agree to the latest version of this Policy.
              <br />
              <br />
              <h2 class="t-redactor__h2">Intellectual Property</h2>
              <br />
              You may not use the Service or Site in any manner that would
              result in an infringement, dilution, misappropriation or other
              violation any intellectual property or proprietary rights of
              others, including but not limited to copyrights and rights arising
              from patents, trademarks and trade secrets.
              <br />
              <br />
              <h2 class="t-redactor__h2">Harmful Content</h2>
              <br />
              You may not use the Service or Site transmit, store, display,
              distribute or otherwise make available content or technology that
              may damage, interfere with, surreptitiously intercept, or
              expropriate any system, program or data, including without
              limitation viruses, Trojan horses, bots, worms, scripting
              exploits, time bombs or other malicious code.
              <br />
              <br />
              <h2 class="t-redactor__h2">No Framing or Scraping</h2>
              <br />
              You may not frame or mirror the Site without CloudCards' express
              prior written consent. You may not use any robot, spider, site
              search/retrieval application or other manual or automatic device
              to retrieve, index, &ldquo;scrape,&rdquo; &ldquo;data mine,&rdquo;
              or in any way gather any messages, text, files, images, photos,
              video, sounds, profiles, works of authorship, or any other content
              from the Service or Site or reproduce or circumvent the
              navigational structure or presentation of the Service or Site
              without CloudCards' express prior written consent. Notwithstanding
              the foregoing, CloudCards grants to the operators of public search
              engines the permission to use spiders to copy material from the
              Site for the sole purpose of, and solely to the extent necessary
              for, creating publicly available searchable indices of such
              material, but not caches or archives of such material. CloudCards
              reserves the right to revoke these exceptions either generally or
              in specific cases.
              <br />
              <br />
              <h2 class="t-redactor__h2">Email and Unsolicited Messages</h2>
              <br />
              You may not use the Service or Site to transmit unsolicited email
              or other messages, including without limitation unsolicited bulk
              email (&ldquo;spam&rdquo;), or email or messages that are
              excessive and/or intended to harass or annoy others. You may not
              continue to send email or other messages to a recipient who has
              indicated that he/she does not wish to receive them. You may not
              alter or obscure email or message headers or assume a
              sender&rsquo;s identity (including without limitation by engaging
              &ldquo;spoofing&rdquo;, &ldquo;phishing&rdquo; or similar attacks)
              without the sender&rsquo;s explicit permission.
              <br />
              <br />
              <h2 class="t-redactor__h2">System Security</h2>
              <br />
              You may not use the Service or Site to violate the security or
              integrity of any network, computer or communications system,
              software application or computing device (each, a
              &ldquo;System&rdquo;), including without limitation by attempting
              to: (a) probe, scan or test the vulnerability of a System or
              breach or circumvent security or authentication measures without
              authorization; (b) make network connections to, or otherwise
              access, a System without authorization; (c) monitor data or
              traffic on a System without authorization; (d) tamper,
              reverse-engineer, hack, interfere with, disrupt or disable a
              System, including without limitation by means of overloading,
              &ldquo;flooding,&rdquo; &ldquo;mailbombing,&rdquo;
              &ldquo;crashing,&rdquo; or denial of service attacks; (e) forge
              any TCP/IP packet header or any part of the header information in
              any e-mail or newsgroup posting; (f) use another party&rsquo;s
              account name or persona without authorization; or (g) take any
              action in order to obtain a Service to which you are not entitled.
              <br />
              <br />
              <h2 class="t-redactor__h2">Privacy</h2>
              <br />
              You may not use the Service or Site to violate the privacy or
              confidentiality of others, including by transmitting, storing,
              displaying, distributing or otherwise making available
              others&rsquo; private or confidential information (including
              without limitation their account names or personal data associated
              with their Service or Site account) without authorization.
              <br />
              <br />
              <h2 class="t-redactor__h2">Compliance with Laws</h2>
              <br />
              Without limiting the foregoing prohibitions, you may not use the
              Service or Site for any illegal purpose or in violation of any
              laws (including without limitation data, privacy, consumer
              protection, and export control laws).
              <br />
              <br />
              <h2 class="t-redactor__h2">No High-Risk Use</h2>
              <br />
              You may not use the Service in any situation where failure or
              fault of the Service could lead to death or serious bodily injury
              of any person, or to physical or environmental damage. For
              example, you may not use, or permit any other person to use, the
              Service in connection with aircraft or other modes of human mass
              transportation or nuclear or chemical facilities.
              <br />
              <br />
              <h2 class="t-redactor__h2">Responsibility for End Users</h2>
              <br />
              You are responsible for violations of this Policy by anyone using
              the Service or Site with your permission or using your account on
              an unauthorized basis. Your use of the Service or Site to assist
              another person in an activity that would violate this Policy if
              performed by you is a violation of this Policy. This Policy
              applies to anyone accessing or using the Service or the Site;
              however, each prohibition included in this Policy shall be
              interpreted to include, and apply to, any action directly or
              indirectly taken, authorized, facilitated, promoted, encouraged or
              permitted by a user of the Service or Site, even if such person
              did not themselves violate the prohibition.
              <br />
              <br />
              <h2 class="t-redactor__h2">Monitoring and Enforcement</h2>
              <br />
              CloudCards reserves the right, but does not assume the obligation,
              to investigate any violation of this Policy or misuse of the
              Service or Site. CloudCards has the right in its sole discretion
              to edit, refuse to post or remove any material submitted to or
              posted on the Service or the Site that CloudCards finds to be in
              violation of this Policy. CloudCards may report any activity that
              it suspects violates any law or regulation to appropriate law
              enforcement officials, regulators, or other appropriate third
              parties. Such reporting may include disclosing appropriate
              customer data. CloudCards also may cooperate with appropriate law
              enforcement agencies, regulators, or other appropriate third
              parties to help with the investigation and prosecution of illegal
              conduct by providing network and systems information related to
              alleged violations of this Policy.
              <br />
              <br />
              <h2 class="t-redactor__h2">Reporting Violations</h2>
              <br />
              If you become aware of any violation of this Policy, you must
              immediately notify CloudCards by email at support@cloudcards.app
              and provide CloudCards with assistance, as requested, to stop or
              remedy the violation.
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acceptable;
