import React from 'react'

import PropTypes from 'prop-types'
import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-footer">
      <footer className="footer-container">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="footer-image"
        />
        <div className="footer-container1">
            <a href={props.text1_link} className="home-text188" style={{ textDecoration: 'none' }}>{props.text1}</a>
            <a href={props.text2_link} className="home-text189" style={{ textDecoration: 'none' }}>{props.text2}</a>
            <a href={props.text3_link} className="home-text190" style={{ textDecoration: 'none' }}>{props.text3}</a>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-container2">
          <span className="footer-text4 body2">{props.text4}</span>
        </div>
      </footer>
    </div>
  )
}

Footer.defaultProps = {
  image_src: '/playground_assets/cloudcards-200h.png',
  image_alt: 'image',
  text1: 'Terms and Conditions',
  text2: 'Privacy Policy',
  text3: 'Acceptable Use',
  text4: '© 2022 CloudCards. All rights reserved',
  text1_link: './terms-and-conditions',
  text2_link: './privacy-policy',
  text3_link: './acceptable-use-policy',
}

Footer.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text1_link: PropTypes.string,
  text2_link: PropTypes.string,
  text3_link: PropTypes.string,
}

export default Footer
