import React, { useState } from 'react';
import PropTypes from 'prop-types'

import './card-promotion.css'
import Example from './modal'

const CardPromotion = (props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className={`card-promotion-container ${props.rootClassName} `}>
      <div className="card-promotion-container1">
        <img
          alt={props.card_image}
          src={props.main_image_src}
          className="card-promotion-image"
        />
      </div>
      <div className="card-promotion-container2">
        <h1 className="card-promotion-text">{props.card_name}</h1>
        <span className="card-promotion-text1">{props.card_description}</span>
        <button className="card-promotion-button button" onClick={handleShow}>
          {props.card_button}
        </button>
        <Example 
          show={show} 
          card_image_src={props.card_image_src}
          card_name={props.card_name}
          link={props.link}
          onClose={handleClose}/>
      </div>
    </div>
  )
}

CardPromotion.defaultProps = {
  card_button: 'Install Card',
  main_image_src: '/playground_assets/stamp.png',
  card_image_src: '/playground_assets/stamp_code.png',
  card_description:
    'Works on the mechanics: Buy 10 and get the 11th as a gift.',
  rootClassName: '',
  card_image: 'image',
  card_name: 'Stamps',
}

CardPromotion.propTypes = {
  card_button: PropTypes.string,
  card_image_src: PropTypes.string,
  card_description: PropTypes.string,
  rootClassName: PropTypes.string,
  card_image: PropTypes.string,
  card_name: PropTypes.string,
}

export default CardPromotion
