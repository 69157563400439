import React, { useState } from 'react';

import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import HeaderContainer from '../components/header-container'
import PrimaryButton from '../components/primary-button'
import StoreBanner from '../components/store-banner'
import CardPromotion from '../components/card-promotion'
import AppLinks from '../components/app-links'
import HowToCreate from '../components/how-to-create'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  const openMenu = () => {
    document.getElementById('Mobile_Menu').style.display = 'block';
    document.getElementById('Mobile_Menu').style.right = 0;
  };
  const closeMenu = () => {
    document.getElementById('Mobile_Menu').style.right = "-500px";
    document.getElementById('Mobile_Menu').style.display = 'none';
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>CloudCards</title>
        <meta property="og:title" content="CloudCards" />
      </Helmet>
      <HeaderContainer rootClassName="header-container-root-class-name"></HeaderContainer>

      <div className="home-main">
        <div className="home-blur-background"></div>
        <div className="home-hero">
          <div className="home-container08">
            <div className="home-container09">
              <h1 className="home-text009 headline1">
                <span>
                  Create a Digital
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Loyalty Program</span>
              </h1>
              <span className="home-text013">
                <span>
                  Get a 2X increase in return rates and 30% increase
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>in revenue with digital loyalty cards.</span>
              </span>
              <div className="home-container10">
                <div className="home-container11">
                  <a
                    href="https://my.cloudcards.app/registration"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link06"
                  >
                    <PrimaryButton
                      button="Get Started"
                      rootClassName="primary-button-root-class-name"
                      className="home-component"
                    ></PrimaryButton>
                  </a>
                  <span className="home-text017">
                    <span>Free for 14 days -</span>
                    <br></br>
                    <span>no credit card required</span>
                  </span>
                </div>
                <StoreBanner rootClassName="store-banner-root-class-name"></StoreBanner>
              </div>
            </div>
            <div className="home-container12"></div>
          </div>
          <div className="home-container13">
            <img
              alt="image"
              src="/playground_assets/iphone%20mockup-1000w.png"
              className="home-image02"
            />
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/turquoise-circle.svg"
          className="home-turquoise-cirble"
        />
        <img
          alt="image"
          src="/playground_assets/cerulean-circle.svg"
          className="home-purple-circle"
        />
        <img
          alt="image"
          src="/playground_assets/left.svg"
          className="home-left"
        />
        <img
          alt="image"
          src="/playground_assets/right.svg"
          className="home-right"
        />
      </div>
      <div className="home-clients">
        <div className="home-divider"></div>
        <img
          alt="image"
          src="/playground_assets/logo-1.svg"
          className="home-image03"
        />
        <img
          alt="image"
          src="/playground_assets/logo-4.svg"
          className="home-image04"
        />
        <img
          alt="image"
          src="/playground_assets/logo-3.svg"
          className="home-image05"
        />
        <img
          alt="image"
          src="/playground_assets/logo-5.svg"
          className="home-image06"
        />
        <img
          alt="image"
          src="/playground_assets/logo-6.svg"
          className="home-image07"
        />
        <div className="home-divider1"></div>
      </div>
      <div className="home-card-types">
        <div className="home-container14">
          <div className="home-container15">
            <h1 className="home-text021">7 Types of Cards</h1>
            <span className="home-text022">
              Try one of the most popular loyalty mechanics
            </span>
          </div>
          <div className="home-container16">
            <div className="home-container17">
              <CardPromotion rootClassName="card-promotion-root-class-name"
                card_image_src="/playground_assets/stamp_code.png"
                link="https://cutt.ly/uZXRZM0"
              ></CardPromotion>
              <CardPromotion
                card_name="Cash Back"
                rootClassName="card-promotion-root-class-name1"
                card_description="Give and redeem customers points for purchases."
                card_image_src="/playground_assets/cashback_code.png"
                link="https://cutt.ly/NZXTPJf"
                main_image_src="/playground_assets/cashback.png"
              ></CardPromotion>
              <CardPromotion
                card_name="Subscription"
                rootClassName="card-promotion-root-class-name2"
                card_description="Sell services in bulk to your customers."
                card_image_src="/playground_assets/subscription_code.png"
                link="https://cutt.ly/QZXFyJP"
                main_image_src="/playground_assets/subscription.png"
              ></CardPromotion>
              <CardPromotion
                card_name="Discount"
                rootClassName="card-promotion-root-class-name3"
                card_description="Classic discount system with several earning levels."
                card_image_src="/playground_assets/discount_code.png"
                link="https://cutt.ly/0ZXOhgy"
                main_image_src="/playground_assets/discount.png"
              ></CardPromotion>
              <CardPromotion
                card_name="Coupon"
                rootClassName="card-promotion-root-class-name4"
                card_description="Attract new customers with digital coupons."
                card_image_src="/playground_assets/coupon_code.png"
                link="https://cutt.ly/UZXHrKD"
                main_image_src="/playground_assets/coupon.png"
              ></CardPromotion>
              <CardPromotion
                card_name="Certificate"
                rootClassName="card-promotion-root-class-name5"
                card_description="Sell prepaid certificates to customers."
                card_image_src="/playground_assets/certificate_code.png"
                link="https://cutt.ly/eZXK53z"
                main_image_src="/playground_assets/certificate.png"
              ></CardPromotion>
              <CardPromotion
                card_name="Membership"
                rootClassName="card-promotion-root-class-name5"
                card_description="Receive a card from the store and become a member."
                card_image_src="/playground_assets/membership_code.png"
                link="https://cutt.ly/cViPzyn"
                main_image_src="/playground_assets/membership.png"
              ></CardPromotion>
            </div>
          </div>
        </div>
      </div>
      <div className="home-apps">
        <div className="home-container18">
          <div className="home-container19">
            <h1 className="home-text023">
              <span>
                Give points using the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                scanner
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                mobile app or
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>the web app</span>
            </h1>
            <strong className="home-text029">
              <span>Easy way to give customer rewards and bonuses</span>
              <span className="home-text031"></span>
            </strong>
            <span className="home-text032">
              <span className="home-text033"></span>
              <span>
                Install our free card scanner app. Give points and stamps for
                purchases the moment your client pays. Provide the app to every
                salesperson in your business and monitor shared statistics.
              </span>
              <span className="home-text035"></span>
            </span>
            <AppLinks rootClassName="app-links-root-class-name"></AppLinks>
          </div>
          <div className="home-container20">
            <img
              alt="image"
              src="/playground_assets/scanner.png"
              className="home-image08"
            />
          </div>
        </div>
      </div>
      <div className="home-how-to">
        <div className="home-container21">
          <div className="home-container22">
            <h1 className="home-text036">How to use</h1>
            <span className="home-text037">
              Set your promotion up in 15 minutes
            </span>
          </div>
          <div className="home-container23">
            <div className="home-container24">
              <HowToCreate
                rootClassName="how-to-create-root-class-name"
                card_image_src="/playground_assets/decagon_dark.svg"
              ></HowToCreate>
              <HowToCreate
                rootClassName="how-to-create-root-class-name1"
                card_image_src="/playground_assets/diamond_dark.svg"
                card_name="Choose a card design"
                card_description="Use one of 111 card designs or create a card of your own design with the help of a simple card builder"
              ></HowToCreate>
              <HowToCreate
                rootClassName="how-to-create-root-class-name2"
                card_name="Create design and content"
                card_description="Upload your logo and colors on card. Add promotion rules, your contact info and business locations."
                card_image_src="/playground_assets/star_dark.svg"
              ></HowToCreate>
              <HowToCreate
                rootClassName="how-to-create-root-class-name3"
                card_image_src="/playground_assets/star2_dark.svg"
                card_name="Download digital card"
                card_description="Service automatically creates A4 file promo in PDF with your promotion rules, cards QR-code, installation instructions."
              ></HowToCreate>
              <HowToCreate
                rootClassName="how-to-create-root-class-name4"
                card_image_src="/playground_assets/star3_dark.svg"
                card_name="Promote your program"
                card_description="Print and place your promo in offline locations and share cards installation link on social networks."
              ></HowToCreate>
              <HowToCreate
                rootClassName="how-to-create-root-class-name5"
                card_image_src="/playground_assets/star4_dark.svg"
                card_name="All set"
                card_description="Now you're done! Issue more cards and track customer activity"
              ></HowToCreate>
            </div>
          </div>
        </div>
      </div>
      <div className="home-pricing">
        <div className="home-container25">
          <h2 className="home-text038 headline2">
            <span className="home-text039">Pricing per month</span>
          </h2>
          <div className="home-container26">
            <div className="home-container27">
              <div className="home-container31">
                <div className="home-container32">
                  <strong className="home-text049">Start</strong>
                  <p className="home-text050">
                    from ₱999/month
                    <br></br>
                    or ₱9,990/year
                  </p>
                  <div>
                    <span className="home-text051">Included</span>
                    <p className="home-text052">
                      <span>1 manager account</span>
                      <br></br>
                      <span>1 promotion</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Unlimited cards</span>
                      <br></br>
                      <span>Free push notifications</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-container34">
                <div className="home-container35">
                  <strong className="home-text062">Grow</strong>
                  <p className="home-text063">
                    from ₱2,499/month
                    <br></br>
                    or ₱24,990/year
                  </p>
                  <div>
                    <span className="home-text064">Included</span>
                    <p className="home-text065">
                      <span>10 manager accounts</span>
                      <br></br>
                      <span>3 promotions</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Referral program</span>
                      <br></br>
                      <span>Phone verification</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-container37">
                <div className="home-container38">
                  <strong className="home-text075">Business</strong>
                  <p className="home-text076">
                    from ₱4,999/month
                    <br></br>
                    or ₱49,990/year
                  </p>
                  <div>
                    <span className="home-text077">Included</span>
                    <p className="home-text078">
                      <span>50 manager accounts</span>
                      <br></br>
                      <span>10 promotions</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Push automation</span>
                      <br></br>
                      <span>API</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-container40">
              <div className="home-container43">
                <div className="home-container44">
                  <a className="home-button8 button" href="https://my.cloudcards.app/registration" target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
              <div className="home-container45">
                <div className="home-container46">
                  <a className="home-button8 button" href="https://my.cloudcards.app/registration" target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
              <div className="home-container47">
                <div className="home-container48">
                  <a className="home-button8 button" href='https://my.cloudcards.app/registration' target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container49">
            <div className="home-container50">
              <div className="home-container54">
                <div className="home-container55">
                  <strong className="home-text097">Start</strong>
                  <p className="home-text098">
                    from ₱999/month
                    <br></br>
                    or ₱9,990/year
                  </p>
                  <div>
                    <span className="home-text099">Included</span>
                    <p className="home-text100">
                      <span>1 manager seat</span>
                      <br></br>
                      <span>1 promotion</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Unlimited cards</span>
                      <br></br>
                      <span>Free push notifications</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-container57">
              <div className="home-container60">
                <div className="home-container61">
                  <a className="home-button8 button" href="https://my.cloudcards.app/registration" target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
            </div>
            <div className="home-container62">
              <div className="home-container63">
                <div className="home-container64">
                  <strong className="home-text110">Grow</strong>
                  <p className="home-text111">
                    from ₱2,499/month
                    <br></br>
                    or ₱24,990/year
                  </p>
                  <div>
                    <span className="home-text112">Included</span>
                    <p className="home-text113">
                      <span>10 manager seats</span>
                      <br></br>
                      <span>3 promotions</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Referral program</span>
                      <br></br>
                      <span>Phone verification</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-container66">
                <div className="home-container67">
                  <strong className="home-text123">Business</strong>
                  <p className="home-text124">
                    from ₱4,999/month
                    <br></br>
                    or ₱49,990/year
                  </p>
                  <div>
                    <span className="home-text125">Included</span>
                    <p className="home-text126">
                      <span>50 manager seat</span>
                      <br></br>
                      <span>10 promotions</span>
                      <br></br>
                      <span>Scanner App</span>
                      <br></br>
                      <span>Built-in CRM</span>
                      <br></br>
                      <span>Analytics</span>
                      <br></br>
                      <span>Push automation</span>
                      <br></br>
                      <span>API</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-container69">
              <div className="home-container70">
                <div className="home-container71">
                  <a className="home-button8 button" href="https://my.cloudcards.app/registration" target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
              <div className="home-container72">
                <div className="home-container73">
                  <a className="home-button8 button" href="https://my.cloudcards.app/registration" target="blank" style={{ textDecoration: 'none' }}>Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container74">
            <div className="home-container75">
              <div className="home-container76">
                <div className="home-container77">
                  <strong className="home-text139">Free</strong>
                  <p className="home-text140">forever</p>
                  <div>
                    <span className="home-text141">Included</span>
                    <p className="home-text142">
                      <span>1 manager seat</span>
                      <br></br>
                      <span>1 promotion</span>
                      <br></br>
                      <span>100 cards</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-container79">
                <div className="home-container80">
                  <strong className="home-text148">Start</strong>
                  <p className="home-text149">
                    from ₱999/month
                    <br></br>
                    or ₱9,990/year
                  </p>
                  <div>
                    <span className="home-text150">Included</span>
                    <p className="home-text151">
                      <span>1 manager seat</span>
                      <br></br>
                      <span>1 promotion</span>
                      <br></br>
                      <span>Unlimited cards</span>
                      <br></br>
                      <span>Free push notifications</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-container82">
              <div className="home-container83">
                <div className="home-container84">
                  <strong className="home-text161">Grow</strong>
                  <p className="home-text162">
                    from ₱2,499/month
                    <br></br>
                    or ₱24,990/year
                  </p>
                  <div>
                    <span className="home-text163">Included</span>
                    <p className="home-text164">
                      <span>10 manager seats</span>
                      <br></br>
                      <span>3 promotions</span>
                      <br></br>
                      <span>Referral program</span>
                      <br></br>
                      <span>Phone verification</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-container86">
                <div className="home-container87">
                  <strong className="home-text174">Business</strong>
                  <p className="home-text175">
                    from ₱4,999/month
                    <br></br>
                    or ₱49,990/year
                  </p>
                  <div>
                    <span className="home-text176">Included</span>
                    <p className="home-text177">
                      <span>50 manager seat</span>
                      <br></br>
                      <span>10 promotions</span>
                      <br></br>
                      <span>Push automation</span>
                      <br></br>
                      <span>API</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-white m-0 mt-3">WE OFFER 14-DAY TRIAL PERIOD TO ALL PLANS</p>
        </div>
      </div>
      <Footer></Footer>     
    </div>
  )
}


export default Home
