import React from 'react'

import PropTypes from 'prop-types'

import './how-to-create-no-img.css'

const HowToCreateNoImg = (props) => {
  return (
    <div className={`how-to-create-no-img-container ${props.rootClassName} `}>
      <div className="how-to-create-no-img-container1">
        <h1 className="how-to-create-no-img-text">{props.card_name}</h1>
        <span className="how-to-create-no-img-text1">
          {props.card_description}
        </span>
      </div>
    </div>
  )
}

HowToCreateNoImg.defaultProps = {
  card_name: 'Create a loyalty card',
  card_description:
    'For example, every 10th coffee as a gift or 10% of the purchase amount is accumulated in the form of points for future purchases',
  rootClassName: '',
}

HowToCreateNoImg.propTypes = {
  card_name: PropTypes.string,
  card_description: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default HowToCreateNoImg
