import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderContainer from '../components/header-container'
import PrimaryButton from '../components/primary-button'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - CloudCards</title>
        <meta property="og:title" content="Contact - CloudCards" />
      </Helmet>
      <HeaderContainer rootClassName="header-container-root-class-name2"></HeaderContainer>
      <div className="contact-container1">
        <div className="contact-container2">
          <img
            alt="image"
            src="/playground_assets/iphone%20mockup-1000w.png"
            className="contact-image"
          />
        </div>
        <div className="contact-container3">
          <div className="contact-container4">
            <h1 className="contact-text">
              <span>Contact Us</span>
              <br></br>
            </h1>
            <span className="contact-text03">
              <span>Have any questions or concerns?</span>
              <br></br>
              <br></br>
              <span>
                Feel free to contact us through the following channels below:
              </span>
              <br></br>
              <span> +63 956 201 1779</span>
              <br></br>
              <span> info@cloudcards.app</span>
            </span>
            <span className="contact-text12">
              Send a request for a free zoom presentation of product features by
              clicking the button below:
            </span>
            <p style= {{ marginBottom: 0, textAlign: "center", width: "100%" }}>
            <a
              href="https://tidycal.com/leantechph/cloudcards-demo"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-link"
            >
              <PrimaryButton
                button="Request a demo"
                rootClassName="primary-button-root-class-name2"
                className="contact-component1"
              ></PrimaryButton>
            </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
