import React from 'react'

import PropTypes from 'prop-types'

import './how-to-create-dark.css'

const HowToCreateDark = (props) => {
  return (
    <div className={`how-to-create-dark-container ${props.rootClassName} `}>
      <div className="how-to-create-dark-container1">
        <img
          alt={props.card_image}
          src={props.card_image_src}
          className={props.card_classes}
        />
      </div>
      <div className="how-to-create-dark-container2">
        <h1 className="how-to-create-dark-text">{props.card_name}</h1>
        <span className="how-to-create-dark-text1">
          {props.card_description}
        </span>
      </div>
    </div>
  )
}

HowToCreateDark.defaultProps = {
  card_name: 'Create a loyalty card',
  card_image: 'image',
  card_description:
    'For example, every 10th coffee as a gift or 10% of the purchase amount is accumulated in the form of points for future purchases',
  rootClassName: '',
  card_image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  card_classes: "how-to-create-image",
}

HowToCreateDark.propTypes = {
  card_name: PropTypes.string,
  card_image: PropTypes.string,
  card_description: PropTypes.string,
  rootClassName: PropTypes.string,
  card_image_src: PropTypes.string,
}

export default HowToCreateDark
