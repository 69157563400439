import { Helmet } from "react-helmet";
import "./home.css";
import "./privacy.css";

const Subscription = (props) => {
  return (
    <div
      className="home-container"
      style={{ fontFamily: "CircularStd,Arial,sans-serif" }}
    >
      <Helmet>
        <title>CloudCards Subscription Agreement</title>
        <meta property="og:title" content="CloudCards Subscription Agreement" />
      </Helmet>
      <div
        className="home-card-types w-100 px-3 documents"
        style={{ maxWidth: "820px", display: "block" }}
      >
        <h1>
          <strong>Subscription Agreement</strong>
        </h1>
        <p>
          <br />
          This Subscription Agreement (this &ldquo;Agreement&rdquo;) contains
          terms and conditions that govern your purchase of subscriptions to,
          and use of, the Services (as defined below), and is a contract between
          CloudCards, Inc., a Delaware corporation (&ldquo;CloudCards&rdquo;),
          and you or the entity or organization that you represent.
          <br />
          <br />
          If you are an individual using the Services for your own purposes: (1)
          all references to &ldquo;Customer&rdquo; are to you and (2) you
          represent and warrant that you are at least 18 years of age, or have
          otherwise reached the age of &ldquo;majority&rdquo; where you reside,
          and that you have the right, power and authority to enter into this
          Agreement.
          <br />
          <br />
          If you are using the Services on behalf of an entity or organization
          that you represent: (1) all references to &ldquo;Customer&rdquo; are
          to that entity or organization and (2) you represent and warrant that
          you are at least 18 years of age, or have otherwise reached the age of
          &ldquo;majority&rdquo; where you reside, and that you have the right,
          power and authority to enter into this Agreement on behalf of
          Customer.
          <br />
          <br />
          This Agreement becomes binding and effective on Customer upon the
          earliest of: (1) when you access or use the Services, (2) when you
          click an &ldquo;I Accept,&rdquo; &ldquo;Sign up&rdquo; or similar
          button or check box referencing this Agreement, or (3) when you enter
          into an Order (as defined below) with CloudCards.
        </p>
        <h3>
          <br />
          <strong>1. Orders.</strong>
        </h3>
        <p>
          <br />
          This Agreement sets forth the terms pursuant to which Customer may
          access and use CloudCards Services in connection with one or more
          Orders. Subject to the terms of an Order, the Services will support
          Customer&rsquo;s operation of a digital customer loyalty program for
          the Customer&rsquo;s business (collectively, but exclusive of the
          subscribed Services, &ldquo;Customer&rsquo;s Environment&rdquo;).
        </p>
        <h3>
          <br />
          <strong>2. Access and Use.</strong>
        </h3>
        <p>
          <br />
          2.1. Subject to the applicable Order and this Agreement, CloudCards
          hereby grants to Customer the right to access and use the Services in
          accordance with the Documentation during the Order Term for
          Customer&rsquo;s Environment.
          <br />
          <br />
          2.2. All rights granted by each Party to the other under this Section
          2 are limited, nonexclusive and, except as otherwise provided in this
          Agreement, non-transferable.
        </p>
        <h3>
          <br />
          <strong>3. Availability.</strong>
        </h3>
        <p>
          CloudCards commits to make the Services Available at least 99.8% of
          the time, exclusive of any time the Services are not Available as a
          result of one or more Exceptions (the &ldquo;Availability
          Standard&rdquo;). If the actual Availability of the Services is less
          than the Availability Standard in any two consecutive months, Customer
          may terminate the applicable Order in the calendar month following
          such two-month period upon written notice to CloudCards. In the event
          of such termination, CloudCards will issue Customer a Pro-Rated Refund
          (as defined in Section 14.4).
          <br />
          <br />
        </p>
        <h3>
          <strong>4. Support.</strong>
        </h3>
        <p>
          <br />
          Subject to this Agreement, CloudCards will provide Support to
          Authorized Users through by email. Although resolution times are not
          guaranteed, CloudCards commits to respond to each request for Support
          from an Authorized User (each, a &ldquo;Support Request&rdquo;) within
          48 hours. Customer&rsquo;s sole and exclusive remedy for any alleged
          failure by CloudCards to provide Support with reasonable skill, care
          and diligence following a Support Request shall be re-performance of
          the applicable Support.
          <br />
          <br />
        </p>
        <h3>
          <strong>5. Security and Privacy.</strong>
        </h3>
        <p>
          <br />
          5.1. Each Party has obligations with respect to the security of the
          Services and Customer Data. Taking into account the nature and types
          of Customer Data, CloudCards will employ administrative, physical and
          technical measures in accordance with applicable industry practice to
          protect the Services and prevent the accidental loss or unauthorized
          access, use, alteration or disclosure of Customer Data under its
          control during each Order Term.
          <br />
          <br />
          5.2. Customer is responsible for properly configuring the Services in
          accordance with the Documentation, enabling single sign-on for
          Customer&rsquo;s accounts, and securing access passwords, keys, tokens
          or other credentials used by Customer in connection with the Services
          (collectively, &ldquo;Customer Credentials&rdquo;). Customer agrees to
          use reasonable efforts to prevent unauthorized access or use of the
          Services and to promptly notify CloudCards if Customer believes (a)
          any Customer Credentials have been lost, stolen or made available to
          an unauthorized third party or (b) an unauthorized third party has
          accessed the Services or Customer Data.
          <br />
          <br />
          5.3. Except for limited Personal Information in Account Data,
          CloudCards does not require Personal Information for Customer&rsquo;s
          access and use of the Services. Customer shall limit Personal
          Information in Account Data to only that necessary for the creation
          and administration of its CloudCards account. With regard to Customer
          Data, Customer shall not use the Services to Process any Sensitive
          Information and shall use reasonable efforts to restrict the inclusion
          of other Personal Information in Customer Data. The Documentation
          provides further information on both filtering Personal Information
          from, and masking Personal Information in, data before they are
          submitted to the Services.
          <br />
          <br />
          5.4. CloudCards may Process information about Customer&rsquo;s
          configuration and use of the Services (&ldquo;Usage Data&rdquo;),
          Customer Data and Account Data: (a) to manage Customer&rsquo;s
          account; (b) to provide and improve the Services and Support,
          including to address Support Requests and troubleshoot other issues;
          and (c) to provide Customer and Authorized Users insights, service and
          feature announcements and other reporting. CloudCards may also Process
          Usage Data that has been aggregated and/or anonymized (including, for
          clarity, that does not allow a third party to identify Customer as the
          source of the information): (i) to develop new services and features
          and (ii) to promote CloudCards&rsquo; services, including, for
          example, through analyses of patterns and trends. CloudCards&rsquo;
          Processing of Usage Data, Customer Data and Account Data shall at all
          times be subject to CloudCards&rsquo; obligations under this
          Agreement, including those of security under Section 5.1 and
          confidentiality under Section 11; the DPA (as defined in Section 7.1),
          if applicable; and, with respect to Account Data, the Privacy Policy.
          <br />
          <br />
        </p>
        <h3>
          <strong>6. Customer Responsibilities and Restrictions.</strong>
        </h3>
        <p>
          <br />
          6.1. Customer will be solely responsible for: (a) Customer&rsquo;s
          Environment, including as necessary to enable Authorized Users&rsquo;
          access and use of the Services; (b) Account Data, Customer Data and
          Customer Credentials (including activities conducted with Customer
          Credentials), subject to CloudCards&rsquo; Processing obligations
          under this Agreement; (c) providing any required notices to, and
          receiving any required consents and authorizations from, Customer
          Component providers, Authorized Users and persons whose Personal
          Information may be included in Account Data, Customer Data or Customer
          Credentials; and (d) ensuring use of the Services is only for
          Customer&rsquo;s Environment and in accordance with the AUP,
          Documentation and applicable Third-Party Terms.
          <br />
          <br />
          6.2. No provision of this Agreement includes the right to, and
          Customer shall not, directly or indirectly: (a) enable any person or
          entity other than Authorized Users to access and use the Services; (b)
          attempt to gain unauthorized access to any Service or its related
          systems or networks; (c) use any Service to access CloudCards
          Intellectual Property Rights except as permitted under this Agreement;
          (d) modify, copy or create any derivative work based upon a Service or
          any portion, feature or function of a Service; (e) resell, distribute
          or otherwise make available any Service to any third party, including
          as part of a managed services offering; (f) except to the extent
          limited by Applicable Law, reverse engineer, disassemble or decompile
          all or any portion of, or attempt to discover or recreate the source
          code for, the Services or access or use the Services or Documentation
          in order to (1) copy ideas, features, functions or graphics, (2)
          develop competing products or services, or (3) perform competitive
          analyses; (g) remove, obscure or alter any proprietary notice related
          to the Services; (h) send or store Malicious Code; (i) use or permit
          others to use the Services in violation of Applicable Law; or (j) use
          or permit others to use the Services other than as described in the
          applicable Order, Documentation and this Agreement.
          <br />
          <br />
          6.3. CloudCards reserves the right to investigate potential violations
          of the above provisions of this Section 6. In the event CloudCards
          reasonably believes a violation has occurred, in addition to any other
          remedies available at law or in equity (including termination pursuant
          to Section 14.2), CloudCards will have the right to suspend Authorized
          Users suspected of the violation from accessing the Services for so
          long as is reasonably necessary to address the potential violation.
          Except where CloudCards reasonably believes the violations are
          willful, or in urgent or emergency situations, CloudCards will notify
          Customer of any such suspension in advance (each, a &ldquo;Suspension
          Notice&rdquo;) and work with Customer in good faith to resolve the
          potential violation. For clarity, CloudCards reserves the right, but
          does not assume any obligation to Customer (except with respect to the
          Suspension Notice), to take any of the actions described in this
          Section 6.3.
          <br />
          <br />
        </p>
        <h3>
          <strong>7. Compliance with Applicable Laws.</strong>
        </h3>
        <p>
          <br />
          Each Party agrees to comply with all Applicable Laws with respect to
          its performance of its obligations and exercise of its rights under
          this Agreement. Without limiting the foregoing:
          <br />
          <br />
          7.1. Each Party shall comply with Applicable Laws concerning the
          privacy and protection of Personal Information. Without limiting
          Section 6.1, Customer will be solely responsible for providing any
          notices required by Applicable Law to, and receiving any consents and
          authorizations required by Applicable Law from, persons whose Personal
          Information may be included in Account Data, Customer Data or Customer
          Credentials. Without limiting Section 5.3, if Customer believes
          Customer Data may include the Personal Information of natural persons
          located in the European Economic Area and wishes to execute a Data
          Processing Addendum (&ldquo;DPA&rdquo;) pursuant to the GDPR, Customer
          may do so by submitting a request by email to support@cloudcards.app.
          Promptly following CloudCards&rsquo; receipt of Customer&rsquo;s
          request, CloudCards will send Customer a DPA ready for execution.
          <br />
          <br />
          7.2. Each Party shall comply with Applicable Laws concerning
          anti-bribery and anti-corruption, which may include the U.S. Foreign
          Corrupt Practices Act of 1977 and the UK Bribery Act 2010. As of the
          date of this Agreement and the date of each Order, Customer represents
          that it has neither received nor been offered any illegal or improper
          bribe, kickback, payment, gift or thing of value from any employee,
          agent or representative of CloudCards or its Affiliates in connection
          with this Agreement. Customer agrees to promptly notify CloudCards if
          it learns of any violation of the foregoing. This representation is
          not intended to include customary and reasonable gifts and
          entertainment provided in the ordinary course of business, to the
          extent such gifts and entertainment are permitted by Applicable Law.
          <br />
          <br />
          7.3. Each Party shall (a) comply with Applicable Laws administered by
          the U.S. Commerce Bureau of Industry and Security, U.S. Treasury
          Office of Foreign Assets Control or other governmental entity imposing
          export controls and trade sanctions (&ldquo;Export Laws&rdquo;),
          including designating countries, entities and persons
          (&ldquo;Sanctions Targets&rdquo;) and (b) not directly or indirectly
          export, re-export or otherwise deliver Services to a Sanctions Target,
          or broker, finance or otherwise facilitate any transaction in
          violation of any Export Laws. Customer represents that it is not a
          Sanctions Target or prohibited from receiving Services pursuant to
          this Agreement under Applicable Laws, including Export Laws.
          <br />
          <br />
        </p>
        <h3>
          <strong>8. Pricing and Fees.</strong>
        </h3>
        <p>
          <br />
          8.1. Customer agrees to pay all fees charged by CloudCards for
          Customer&rsquo;s use of Services in accordance with this Agreement and
          applicable Order(s) and Service Plan(s) (collectively,
          &ldquo;Fees&rdquo;). Except as otherwise provided in an Order: (a)
          Fees for Services are set forth on the Pricing Page; (b) Fees must be
          paid in U.S. dollars and, subject to Section 6.2, at the time the
          applicable Order is made; and (c) Fees for Services include Support at
          no additional charge.&nbsp;
          <br />
          <br />
          8.2. If Customer is paying Fees using a credit card or any digital
          payment method supported by CloudCards, Customer authorizes CloudCards
          to charge Customer&rsquo;s account for the Services using that payment
          method. Customer must keep all information in its billing account
          current to ensure that all Fees are charged to the appropriate account
          and are timely paid. If Customer notifies CloudCards to stop using a
          previously designated payment method and fails to designate an
          alternative, CloudCards may immediately suspend use and access to the
          Services. Any notice from Customer changing its billing account will
          not affect charges CloudCards submits to Customer&rsquo;s billing
          account before CloudCards reasonably can act on Customer&rsquo;s
          request. CloudCards uses a third-party intermediary to manage credit
          card processing, and this intermediary is not permitted to use
          Customer&rsquo;s credit card information except in connection with
          Customer&rsquo;s authorized purchases. Notice (including email) from
          CloudCards&rsquo; third-party credit card processor declining
          Customer&rsquo;s credit card or otherwise relating to Customer&rsquo;s
          account will be deemed valid notice from CloudCards.
          <br />
          <br />
        </p>
        <h3>
          <strong>9. Taxes.</strong>
        </h3>
        <p>
          <br />
          All Fees are exclusive of taxes, levies, duties or charges imposed by
          government authorities (collectively, &ldquo;Taxes&rdquo;). Customer
          shall be solely responsible for all sales, service, value-added, use,
          excise, consumption and any other Taxes on amounts payable by Customer
          under the Orders and this Agreement (other than any Taxes on
          CloudCards&rsquo; income, revenues, gross receipts, personnel or
          assets). Without limiting the foregoing, if Customer is required to
          deduct or withhold any Taxes under Applicable Laws outside the United
          States, Customer shall remit such Taxes in accordance with those
          Applicable Laws and all Fees payable shall be increased so that
          CloudCards receives an amount equal to the sum it would have received
          had no withholding or deduction been made.
          <br />
          <br />
        </p>
        <h3>
          <strong>10. Ownership.</strong>
        </h3>
        <p>
          <br />
          As between the Parties: (a) Customer owns all right, title and
          interest in and to Customer&rsquo;s Environment and Customer Data,
          including in each case all associated Intellectual Property Rights,
          and (b) CloudCards owns all right, title and interest in and to the
          Services, Documentation and Feedback, including in each case all
          associated Intellectual Property Rights. Except for the rights
          expressly granted by one Party to the other in this Agreement, all
          rights are reserved by the granting Party.
          <br />
          <br />
        </p>
        <h3>
          <strong>11. Confidentiality.</strong>
        </h3>
        <p>
          <br />
          11.1. As used in this Agreement, &ldquo;Confidential
          Information&rdquo; means any information disclosed by one Party, its
          Affiliates, business partners or their respective employees, agents or
          contractors (collectively, the &ldquo;Discloser&rdquo;) that is
          designated as confidential, either orally or in writing, or that,
          given the nature of the information or circumstances surrounding its
          disclosure, reasonably should be understood to be confidential.
          Confidential Information includes without limitation: (a) Customer
          Data; (b) information relating to the Discloser&rsquo;s or its
          Affiliates&rsquo; technology, customers, business plans, promotional
          and marketing activities, finances and other business affairs; (c)
          third-party information that the Discloser is obligated to keep
          confidential; and (d) the terms of this Agreement and all Orders.
          However, Confidential Information does not include any information
          that: (i) was known to the Party that receives any Confidential
          Information (the &ldquo;Recipient&rdquo;) prior to receiving the same
          from the Discloser in connection with this Agreement; (ii) is
          independently developed by the Recipient without reference to or use
          of the Discloser&rsquo;s Confidential Information; (iii) is acquired
          by the Recipient from another source without restriction as to use or
          disclosure; or (iv) is or becomes publicly available through no fault
          or action of the Recipient.
          <br />
          <br />
          11.2. The Recipient shall not (a) use the Discloser&rsquo;s
          Confidential Information for any purpose outside the scope of this
          Agreement without the Discloser&rsquo;s prior written consent or (b)
          disclose the Discloser&rsquo;s Confidential Information to any person
          or entity, except to the Recipient&rsquo;s employees, agents,
          contractors and service providers who (i) are bound by non-use and
          non-disclosure obligations at least as protective as those contained
          in this Agreement and (ii) have a need to know the Confidential
          Information for the Recipient to exercise its rights or perform its
          obligations under this Agreement. Notwithstanding the foregoing, the
          Recipient may disclose the Discloser&rsquo;s Confidential Information
          to the limited extent any use or disclosure is required by Applicable
          Law or a valid and binding order of a governmental body (such as a
          subpoena or court order), provided that, to the extent permitted under
          Applicable Law, the Recipient uses reasonable efforts to give the
          Discloser reasonable advance notice thereof to afford the Discloser an
          opportunity to intervene and seek an order or other appropriate relief
          for the protection of its Confidential Information. In the event of
          any breach or threatened breach by the Recipient of its obligations
          under this Section, the Discloser will be entitled to seek injunctive
          and other equitable relief to enforce such obligations.
          <br />
          <br />
        </p>
        <h3>
          <strong>12. Disclaimers.</strong>
        </h3>
        <p>
          <br />
          12.1. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, NEITHER PARTY
          MAKES ANY WARRANTY OR GUARANTEE OF ANY KIND, WHETHER EXPRESS, IMPLIED,
          STATUTORY, OR OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL
          WARRANTIES, WHETHER IMPLIED, EXPRESS, OR STATUTORY, INCLUDING ANY
          IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE
          OF DEALING, USAGE OR TRADE PRACTICE, TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW.
          <br />
          <br />
          12.2. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, ALL SERVICES,
          SUPPORT AND ANY OTHER MATERIAL ARE PROVIDED BY CLOUDCARDS ON AN
          &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. CLOUDCARDS
          MAKES NO REPRESENTATION OR WARRANTY, AND HAS NO SUPPORT OBLIGATIONS OR
          LIABILITY, WITH RESPECT TO ANY CUSTOMER COMPONENT. WITHOUT LIMITING
          THE OTHER PROVISIONS OF THIS SECTION 13, CLOUDCARDS MAKES NO WARRANTY
          OF ANY KIND THAT THE SERVICES, DOCUMENTATION, ANCILLARY TOOLS OR ANY
          OTHER MATERIAL, OR RESULTS OF THE USE THEREOF, WILL: (a) MEET
          CUSTOMER&rsquo;S OR ANY OTHER PERSON&rsquo;S REQUIREMENTS; (b) OPERATE
          WITHOUT INTERRUPTION; (c) ACHIEVE ANY INTENDED RESULT; (d) BE ERROR
          FREE OR (e) BE COMPATIBLE, WORK WITH OR CONTINUE TO WORK WITH CUSTOMER
          COMPONENTS. ANY CHANGES TO CUSTOMER COMPONENTS (INCLUDING THEIR
          UNAVAILABILITY) OR THIRD-PARTY TERMS DURING AN ORDER TERM DO NOT
          AFFECT CUSTOMER&rsquo;S OBLIGATIONS UNDER THE APPLICABLE ORDER OR THIS
          AGREEMENT.
          <br />
          <br />
        </p>
        <h3>
          <strong>13. Term and Termination.</strong>
        </h3>
        <p>
          <br />
          13.1. The term of this Agreement will continue through the expiration
          or earlier termination of the last Order to be in effect.
          <br />
          <br />
          13.2. Upon expiration or earlier termination of an Order: (a) subject
          to Section 13.4, all rights granted to Customer with respect to
          Services under such Order will terminate effective as of the effective
          date of termination; and (b) subject to Section 13.4, CloudCards will
          have no obligation to provide Services to Customer or Authorized Users
          after the effective date of the termination
          <br />
          <br />
          13.3. If an Order is terminated early by Customer pursuant to Section
          3, or by CloudCards pursuant to Section 15.2: (a) Customer shall not
          be obligated to pay any additional amounts specified in the Order
          following the effective date of termination and (b) CloudCards will
          refund to Customer a pro rata share of any unused amounts prepaid by
          Customer under the applicable Order for the Services on the basis of
          the remaining portion of the current Order Term (a &ldquo;Pro-Rated
          Refund&rdquo;). In all other cases, and regardless of whether Customer
          uses the Services at the levels reflected in the Orders or otherwise,
          Customer will not be entitled to a refund of Fees paid and any unpaid
          Fees outstanding will become immediately due and payable.
          <br />
          <br />
          13.4. Provided Customer has paid all amounts due under this Agreement,
          and subject to any applicable shorter Service Plan retention periods,
          for up to 30 days from the effective date of termination of this
          Agreement an Authorized User designated by Customer will be permitted
          to continue to access and download Customer Data that was accessible
          to Authorized Users through the Services immediately prior to
          termination. The designated Authorized User&rsquo;s access and use
          will continue to be subject to the terms of this Agreement, provided
          the Authorized User shall not access or use the Services other than to
          download Customer Data.
          <br />
          <br />
          13.5. The provisions set forth in the following Sections, and any
          other right or obligation of the Parties in this Agreement that, by
          its nature, should survive termination or expiration of this
          Agreement, will survive any expiration or termination of this
          Agreement: 5.4, 6.2, 7, 9 through 16, and 18 through 28.
          <br />
          <br />
        </p>
        <h3>
          <strong>14. Indemnification.</strong>
        </h3>
        <p>
          <br />
          14.1. Subject to Sections 14.2 and 14.4, CloudCards agrees to defend,
          indemnify and hold harmless Customer, its Participating Affiliates (as
          defined in Section 19) and their employees, contractors, agents,
          officers and directors (collectively, &ldquo;Customer
          Indemnitees&rdquo;), from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including without limitation attorneys&rsquo; fees) (collectively,
          &ldquo;Losses&rdquo;) arising out of or related to any legal claim,
          suit, action or proceeding (each, an &ldquo;Action&rdquo;) by a third
          party alleging use of the Services as permitted under this Agreement
          infringes such third party&rsquo;s United States patent or copyright,
          or misappropriates such third party&rsquo;s trade secrets (each, a
          &ldquo;Customer Infringement Claim&rdquo;).
          <br />
          <br />
          14.2. If the Services become, or in CloudCards&rsquo; opinion are
          likely to become, the subject of a Customer Infringement Claim,
          CloudCards may in its discretion and at its own expense: (a) obtain
          for Customer the right to continue using the Services; (b) modify the
          Services so that they no longer infringe or misappropriate; or (c)
          terminate this Agreement and all Orders and issue a Pro-Rated Refund.
          CloudCards will have no obligation to indemnify Customer for a
          Customer Infringement Claim to the extent it arises from any of the
          following (collectively, &ldquo;Customer-Controlled Matters&rdquo;):
          (i) Customer&rsquo;s Environment, including Connections to Customer
          Components, whether enabled through APIs, Ancillary Tools or
          otherwise; (ii) Account Data, Customer Data or Customer Credentials
          (including activities conducted with Customer Credentials), subject to
          CloudCards&rsquo; Processing obligations under this Agreement; or
          (iii) use of the Services by Customer or an Authorized User in a
          manner that breaches an Order, Service Plan or this Agreement.
          SECTIONS 14.1 AND 14.2 STATE CLOUDCARDS&rsquo; ENTIRE LIABILITY AND
          CUSTOMER&rsquo;S EXCLUSIVE REMEDIES FOR ANY CLAIM OF INTELLECTUAL
          PROPERTY RIGHTS INFRINGEMENT OR MISAPPROPRIATION.
          <br />
          <br />
          14.3. Subject to Section 14.4, Customer agrees to defend, indemnify
          and hold harmless CloudCards, its Affiliates and their employees,
          contractors, agents, officers and directors (collectively,
          &ldquo;CloudCards Indemnitees&rdquo;), from and against any and all
          Losses arising out of or related to any Action by a third party
          arising out of or relating to Customer-Controlled Matters.
          <br />
          <br />
          14.4. A Customer Indemnitee or CloudCards Indemnitee (each, an
          &ldquo;Indemnitee&rdquo;) seeking indemnification shall promptly
          notify the other Party (each, an &ldquo;Indemnifying Party&rdquo;), in
          writing of any Action for which it seeks indemnification pursuant to
          Section 14.1 or 14.3 (as applicable) and cooperate with the
          Indemnifying Party at the Indemnifying Party&rsquo;s expense. The
          Indemnifying Party shall promptly take control of the defense and
          investigation of such Action and shall employ counsel of its choice to
          handle and defend the same, at the Indemnifying Party&rsquo;s expense.
          An Indemnitee may participate in and observe the proceedings at its
          own expense with counsel of its own choice. A Party&rsquo;s failure to
          perform any obligations under this Section 14.4 will not relieve the
          Indemnifying Party of its obligations under Section 14.1 or 14.3 (as
          applicable) except to the extent that the Indemnifying Party can
          demonstrate that it has been materially prejudiced as a result of such
          failure. The Indemnifying Party shall not settle an Action without the
          Indemnitee&rsquo;s written consent if such settlement shall require
          action or payment by the Indemnitee.
          <br />
          <br />
        </p>
        <h3>
          <strong>15. Limitations of Liability.</strong>
        </h3>
        <p>
          <br />
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS OTHERWISE
          PROVIDED IN THIS SECTION 15: (a) IN NO EVENT SHALL EITHER PARTY, ITS
          AFFILIATES OR THEIR EMPLOYEES, AGENTS, CONTRACTORS, OFFICERS OR
          DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE,
          DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THIS
          AGREEMENT; AND (b) IN NO EVENT SHALL EITHER PARTY&rsquo;S CUMULATIVE
          AND AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED THE FEES PAID TO
          CLOUDCARDS BY CUSTOMER UNDER THE APPLICABLE ORDER(S), INCLUDING PRIOR
          ORDERS FOR THE SAME SERVICES, IN THE 12 MONTHS PRECEDING THE EVENT
          GIVING RISE TO THE LIABILITY. THE EXCLUSIONS AND LIMITATIONS IN THIS
          SECTION (COLLECTIVELY, THE &ldquo;EXCLUSIONS&rdquo;) APPLY WHETHER THE
          ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY OR ANY OTHER BASIS, EVEN IF THE NON-BREACHING PARTY HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE EXCLUSIONS SHALL NOT
          APPLY TO A PARTY&rsquo;S INDEMNIFICATION OBLIGATIONS UNDER SECTION 15,
          CUSTOMER&rsquo;S BREACH OF SECTION 6.2, OR CUSTOMER&rsquo;S PAYMENT
          OBLIGATIONS TO CLOUDCARDS UNDER THIS AGREEMENT. THE PROVISIONS OF THIS
          SECTION 15 ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE
          PARTIES, AND THE PARTIES HAVE RELIED ON THE EXCLUSIONS IN DETERMINING
          TO ENTER INTO THIS AGREEMENT AND THE PRICING FOR THE SERVICES.
          <br />
          <br />
        </p>
        <h3>
          <strong>16. Publicity.</strong>
        </h3>
        <p>
          <br />
          Neither Party shall, except as otherwise required by Applicable Law or
          stock exchange requirements, issue or release any announcement,
          statement, press release or other publicity or marketing materials
          relating to this Agreement or otherwise use the other Party&rsquo;s
          marks or logos without the prior written consent of the other Party;
          provided, however, that CloudCards may (subject its obligations of
          non-attribution under Section 5.4) include Customer&rsquo;s name and
          logo in its lists of CloudCards customers, its public website and
          other promotional material. CloudCards agrees to promptly cease such
          uses of Customer&rsquo;s name and logo following Customer&rsquo;s
          request sent to support@cloudcards.app.
          <br />
          <br />
        </p>
        <h3>
          <strong>17. Notices.</strong>
        </h3>
        <p>
          <br />
          Subject to change pursuant to this Section: (a) CloudCards&rsquo;
          physical address for notices is at CloudCards, Inc., 24A Trolley
          Square, Unit #4087, Wilmington, DE 19806, and its email address for
          notices is support@cloudcards.app and (b) Customer&rsquo;s physical
          and email addresses for notices are those associated with its
          Order(s). Notices required or permitted to be given under this
          Agreement shall be in writing and shall be deemed to be sufficiently
          given: (i) one business day after being sent by overnight courier to
          the Party&rsquo;s physical address; (ii) three business days after
          being sent by registered mail, return receipt requested, to the
          Party&rsquo;s physical address; or (iii) one business day after being
          sent by email to the Party&rsquo;s email address (provided that (1)
          the sender does not receive a response that the message could not be
          delivered or an out-of-office reply and (2) any notice for an
          indemnifiable Action must be sent by courier or mail pursuant to
          clause (i) or (ii)). Either Party may change its address(es) for
          notice by providing notice to the other in accordance with this
          Section.
          <br />
          <br />
        </p>
        <h3>
          <strong>18. Customer Affiliates.</strong>
        </h3>
        <p>
          <br />
          Where an Affiliate of Customer has not entered into an Order or other
          separate agreement directly with CloudCards, Customer may authorize
          that Affiliate (each, a &ldquo;Participating Affiliate&rdquo;) to
          access and use the Services under an existing Order between CloudCards
          and Customer. In such cases, references to &ldquo;Customer&rdquo; in
          the applicable Order and this Agreement will be deemed references to
          both Customer and the Participating Affiliate. Customer and its
          Participating Affiliates will be jointly and severally liable for
          compliance with this Agreement and all Orders hereunder. As between
          CloudCards and Customer, Customer accepts full liability for the acts
          and omissions of its Participating Affiliates.
          <br />
          <br />
        </p>
        <h3>
          <strong>19. Assignment.</strong>
        </h3>
        <p>
          <br />
          So long as Customer remains current in the payment of all amounts when
          due, Customer may assign this Agreement in connection with any merger,
          consolidation or reorganization involving Customer (regardless of
          whether Customer is a surviving or disappearing entity), or a sale of
          all or substantially all of Customer&rsquo;s business or assets
          relating to this Agreement to an unaffiliated third party. Subject to
          the foregoing, Customer may not assign any of its rights or obligation
          under this Agreement, whether by operation of law or otherwise,
          without CloudCards&rsquo; prior written consent, and any purported
          assignment in violation of this Section is void. This Agreement is
          binding upon and inures to the benefit of the Parties hereto and their
          respective permitted successors and assigns.
          <br />
          <br />
        </p>
        <h3>
          <strong>20. U.S. Government Customers.</strong>
        </h3>
        <p>
          <br />
          The Services and Documentation are provided to the U.S. Government as
          &ldquo;commercial items,&rdquo; &ldquo;commercial computer
          software,&rdquo; &ldquo;commercial computer software
          documentation,&rdquo; and &ldquo;technical data&rdquo; with the same
          rights and restrictions generally applicable to the Services and
          Documentation. If Customer or any Authorized User is using Services
          and Documentation on behalf of the U.S. Government and these terms
          fail to meet the U.S. Government&rsquo;s needs or are inconsistent in
          any respect with federal law, Customer and Customer&rsquo;s Authorized
          Users must immediately discontinue use of the Services and
          Documentation. The terms listed above are defined in the Federal
          Acquisition Regulation and the Defense Federal Acquisition Regulation
          Supplement.
          <br />
          <br />
        </p>
        <h3>
          <strong>
            21. Independent Parties; No Third-Party Beneficiaries.
          </strong>
        </h3>
        <p>
          <br />
          The Parties expressly understand and agree that their relationship is
          that of independent contractors. Nothing in this Agreement shall
          constitute one Party as an employee, agent, joint venture partner or
          servant of another. This Agreement is for the sole benefit of the
          Parties hereto and their respective successors and permitted assigns
          and nothing herein, express or implied, is intended to or shall confer
          on any other person any legal or equitable right, benefit or remedy of
          any nature whatsoever under or by reason of this Agreement.
          <br />
          <br />
        </p>
        <h3>
          <strong>22. Force Majeure.</strong>
        </h3>
        <p>
          <br />
          Neither Party shall be liable or responsible to the other Party, nor
          be deemed to have defaulted under or breached this Agreement, for any
          failure or delay in fulfilling or performing any term of this
          Agreement (except for any obligations to make payments), when and to
          the extent such failure or delay is caused by acts of God; flood, fire
          or explosion; war, terrorism, invasion, riot or other civil unrest;
          embargoes or blockades in effect on or after the date of this
          Agreement; or national or regional emergency (each of the foregoing, a
          &ldquo;Force Majeure Event&rdquo;), in each case, provided the event
          is outside the reasonable control of the affected Party, the affected
          Party provides prompt notice to the other Party, stating the period of
          time the occurrence is expected to continue, and the affected Party
          uses diligent efforts to end the failure or delay and minimize the
          effects of such Force Majeure Event.
          <br />
          <br />
        </p>
        <h3>
          <strong>23. Governing Law; Venue.</strong>
        </h3>
        <p>
          <br />
          Except to the extent the issue arising under this Agreement is
          governed by United States federal law, this Agreement shall be
          governed by and construed and enforced in accordance with the laws of
          the State of Delaware, without giving effect to the choice of law
          rules of that State. Any legal action or proceeding arising under or
          relating to this Agreement shall be brought exclusively in the state
          or federal courts located in New Castle County, State of Delaware,
          USA, and the Parties expressly consent to personal jurisdiction and
          venue in those courts. The Parties agree that the United Nations
          Convention on Contracts for the International Sale of Goods are
          specifically excluded from application to this Agreement.
          <br />
          <br />
        </p>
        <h3>
          <strong>24. Miscellaneous.</strong>
        </h3>
        <p>
          <br />
          This Agreement, together with all Orders, and the AUP, is the complete
          and exclusive statement of the agreement between the Parties and
          supersedes all proposals, questionnaires and other communications and
          agreements between the Parties (oral or written) relating to the
          subject matter of this Agreement. Any terms and conditions of any
          other instrument issued by Customer in connection with this Agreement
          which are in addition to, inconsistent with or different from the
          terms and conditions of this Agreement shall be of no force or effect.
          Additionally, this Agreement supersedes any confidentiality,
          non-disclosure, evaluation or trial agreement previously entered into
          by the Parties with respect Customer&rsquo;s or an Affiliate&rsquo;s
          evaluation of the Services or otherwise with respect to the Services.
          Except as otherwise provided in Section 30, this Agreement may be
          modified only by a written instrument duly executed by authorized
          representatives of the Parties. The failure of a Party to exercise or
          enforce any condition, term or provision of this Agreement will not
          operate as a waiver of such condition, term or provision. Any waiver
          by either Party of any condition, term or provision of this Agreement
          shall not be construed as a waiver of any other condition, term or
          provision. If any provision of this Agreement is held invalid or
          unenforceable, the remainder of the Agreement shall continue in full
          force and effect. The headings in this Agreement are for reference
          only and shall not affect the interpretation of this Agreement. For
          purposes of this Agreement, the words &ldquo;include,&rdquo;
          &ldquo;includes&rdquo; and &ldquo;including&rdquo; are deemed to be
          followed by the words &ldquo;without limitation&rdquo;; the word
          &ldquo;or&rdquo; is not exclusive; and the words &ldquo;herein,&rdquo;
          &ldquo;hereof,&rdquo; &ldquo;hereby,&rdquo; &ldquo;hereto&rdquo; and
          &ldquo;hereunder&rdquo; refer to this Agreement as a whole.
          <br />
          <br />
        </p>
        <h3>
          <strong>25. Definitions.</strong>
        </h3>
        <p>
          <br />
          Capitalized terms not otherwise defined in this Agreement shall have
          the respective meanings assigned to them in this Section 26.
          <br />
          <br />
          &ldquo;Account Data&rdquo; means information about Customer that
          Customer provides to CloudCards in connection with the creation or
          administration of its CloudCards account, such as first and last name,
          user name and email address of an Authorized User or Customer&rsquo;s
          billing contact. Customer shall ensure that all Account Data is
          current and accurate at all times during the applicable Order Term,
          and shall in no event include Sensitive Information in Account Data.
          <br />
          <br />
          &ldquo;Affiliate&rdquo; means, with respect to a Party, a business
          entity that directly or indirectly controls, is controlled by or is
          under common control with, such Party, where &ldquo;control&rdquo;
          means the direct or indirect ownership of more than 50% of the voting
          securities of a business entity.
          <br />
          <br />
          &ldquo;Applicable Laws&rdquo; means any and all governmental laws,
          rules, directives, regulations or orders that are applicable to a
          particular Party&rsquo;s performance under this Agreement.
          <br />
          <br />
          &ldquo;AUP&rdquo; means CloudCards&rsquo; standard Acceptable Use
          Policy, currently available at&nbsp;
          <u>
            <a href="https://cloudcards.app/acceptable-use-policy">
              https://cloudcards.app/acceptable-use-policy
            </a>
          </u>
          .<br />
          <br />
          &ldquo;Authorized User&rdquo; means an individual employee, agent or
          contractor of Customer or a Participating Affiliate for whom
          subscriptions to Services have been purchased pursuant to the terms of
          the applicable Order and this Agreement, and who has been supplied
          user credentials for the Services by Customer or the Participating
          Affiliate (or by CloudCards at Customer&rsquo;s or a Participating
          Affiliate&rsquo;s request).
          <br />
          <br />
          &ldquo;Available&rdquo; means the Services are available for access
          and use by end users over the internet; &ldquo;Availability&rdquo; has
          a correlative meaning. Availability is assessed from the point where
          the Services are made available from CloudCards&rsquo; hosting
          provider and measured in minutes over the course of each calendar
          month during the Order Term. Customer may request Availability
          information by submitting a Support Request.
          <br />
          <br />
          &ldquo;Documentation&rdquo; means CloudCards&rsquo; standard user
          documentation for the Services, currently available at&nbsp;
          <a href="https://cloudcards.app/">https://cloudcards.app/</a>.
          <br />
          <br />
          &ldquo;Exceptions&rdquo; means any of: (a) Customer&rsquo;s breach of
          this Agreement, an Order or the AUP; (b) Customer&rsquo;s failure to
          configure and use the Services in accordance with the Documentation;
          (c) failures of, or issues with, Customer&rsquo;s Environment; (d)
          Force Majeure Events; (e) CloudCards&rsquo; suspension of Authorized
          Users&rsquo; access to the Services pursuant to Section 8.3 or 16.2;
          or (f) maintenance during a window for which CloudCards provides
          notice by email or through the Services in advance.
          <br />
          <br />
          &ldquo;Feedback&rdquo; means bug reports, suggestions or other
          feedback with respect to the Services or Documentation provided by
          Customer to CloudCards, exclusive of any Customer Confidential
          Information therein.
          <br />
          <br />
          &ldquo;GDPR&rdquo; means the General Data Protection Regulation 2016 /
          679 of the European Parliament and of the Council of 27 April 2016 on
          the protection of natural persons with regard to the processing of
          personal data and on the free movement of such data, and repealing of
          Directive 95/46/EC.
          <br />
          <br />
          &ldquo;Intellectual Property Rights&rdquo; means any and all
          registered and unregistered rights granted, applied for, or otherwise
          now or hereafter in existence under or related to any patent,
          copyright, trademark, trade secret, database protection, or other
          intellectual property rights laws, and all similar or equivalent
          rights or forms of protection, in any part of the world.
          <br />
          <br />
          &ldquo;Malicious Code&rdquo; means viruses, worms, time bombs, Trojan
          horses and other harmful or malicious code, files, scripts, agents or
          programs.
          <br />
          <br />
          &ldquo;Order&rdquo; means a separate order for Services pursuant to
          this Agreement: (a) completed and submitted by Customer online at the
          CloudCards site and accepted by CloudCards or (b) executed by
          CloudCards and Customer.
          <br />
          <br />
          &ldquo;Order Term&rdquo; means, with respect to each Order, the
          initial subscription term for the Services specified in the applicable
          Order and all Renewal Order Terms, if any. In the event an Order does
          not specify a fixed term, then the Order Term will run from the
          Order&rsquo;s effective date until the end of the calendar month in
          which either Party gives notice of termination in accordance with
          Section 20, unless the Order is otherwise terminated earlier in
          accordance with this Agreement or the Order.
          <br />
          <br />
          &ldquo;Party&rdquo; means each of CloudCards and Customer.
          <br />
          <br />
          &ldquo;Personal Information&rdquo; means information relating to an
          identified or identifiable natural person that is protected by
          Applicable Laws with respect to privacy where the individual resides.
          <br />
          <br />
          &ldquo;Pricing Page&rdquo; means the publicly available web page(s)
          where CloudCards publishes its list prices for Services, currently
          available at&nbsp;
          <a href="https://cloudcards.app/pricing">
            https://cloudcards.app/pricing
          </a>
          .<br />
          <br />
          &ldquo;Privacy Policy&rdquo; means CloudCards&rsquo; standard Privacy
          Policy, currently available at&nbsp;
          <a href="https://cloudcards.app/gdpr/tpost/teyah34dd1-privacy-policy">
            https://
          </a>
          <a href="https://cloudcards.app/pricing">cloudcards</a>
          <a href="https://cloudcards.app/gdpr/tpost/teyah34dd1-privacy-policy">
            .app/privacy-policy
          </a>
          .<br />
          <br />
          &ldquo;Private Label Policy&rdquo; means CloudCards&rsquo; standard
          Private Label Policy, currently available at&nbsp;
          <a href="https://cloudcards.app/gdpr/tpost/ehbd9ypnr1-private-label-policy">
            https://
          </a>
          <a href="https://cloudcards.app/pricing">cloudcards</a>
          <a href="https://cloudcards.app/gdpr/tpost/ehbd9ypnr1-private-label-policy">
            .app/private-label-policy
          </a>
          .<br />
          <br />
          &ldquo;Process&rdquo; means to perform an operation or set of
          operations on data, content or information, including to submit,
          transmit, post, transfer, disclose, collect, record, organize,
          structure, store, adapt or alter; &ldquo;Processing&rdquo; has a
          correlative meaning.
          <br />
          <br />
          &ldquo;Sensitive Information&rdquo; means the following categories of
          Personal Information: (a) government-issued identification numbers,
          including Social Security numbers; (b) financial account data; (c)
          biometric, genetic, health or insurance data; (d) financial
          information; (e) data revealing race, ethnicity, political opinions,
          religion, philosophical beliefs or trade union membership; (f) data
          concerning sex life or sexual orientation; and (g) data relating
          criminal convictions and offenses. Without limiting the foregoing, the
          term &ldquo;Sensitive Information&rdquo; includes Personal Information
          that is subject to specific or heightened requirements under
          Applicable Law or industry standards, such as Social Security numbers
          in the United States, protected health information under the U.S.
          Health Insurance Portability and Accountability Act, nonpublic
          personal information under the U.S. Gramm-Leach-Bliley Act, cardholder
          data under the PCI Data Security Standard, and special categories of
          personal data under the GDPR.
          <br />
          <br />
          &ldquo;Service Plan&rdquo; means the packaged plan and associated
          features, as detailed on the Pricing Page, for the hosted CloudCards
          service to which the Customer subscribes.
          <br />
          <br />
          &ldquo;Services&rdquo; means the hosted services to which the Customer
          subscribes through, or otherwise uses following, an Order that is made
          available by CloudCards online via the applicable login page and other
          web pages designated by CloudCards. CloudCards may make such changes
          to the Services as CloudCards deems appropriate from time to time,
          provided such changes do not materially decrease the features or
          functionality of the Services as they existed at the effective date of
          this Agreement.&nbsp;
          <br />
          <br />
          &ldquo;Support&rdquo; means CloudCards&rsquo; standard customer
          technical support for the Services, currently provided exclusively via
          email.
          <br />
          <br />
        </p>
        <h3>
          <strong>26. Counterparts.</strong>
        </h3>
        <p>
          <br />
          Any written Order may be executed in counterparts, each of which shall
          be deemed an original, but all of which together shall be deemed to be
          one and the same agreement. Delivery of an executed counterpart of a
          signature page to an Order by fax or by email of a scanned copy, or
          execution and delivery through an electronic signature service (such
          as DocuSign), shall be effective as delivery of an original executed
          counterpart of the relevant Order.
          <br />
          <br />
        </p>
        <h3>
          <strong>27. Changes to this Agreement.</strong>
        </h3>
        <p>
          <br />
          CloudCards may modify this Agreement at any time by posting a revised
          version at&nbsp;
          <u>
            <a href="https://cloudcards.app/gdpr/subscription-agreement">
              https://cloudcards.app/subscription-agreement
            </a>
          </u>
          , which modifications will become effective as of the first day of the
          calendar month following the month in which they were first posted;
          provided, however, that if an Order specifies a fixed term of 12
          months or longer, the modifications will instead be effective
          immediately upon the start of the next Renewal Order Term. In either
          case, if Customer objects to the updated Agreement, as its sole and
          exclusive remedy, Customer may choose not to renew, including
          canceling any terms set to auto-renew. For the avoidance of doubt, any
          Order is subject to the version of the Agreement in effect at the time
          of the Order.
        </p>
      </div>
    </div>
  );
};

export default Subscription;
